<template>
  <div>
    <div style="background-color: white">
      <div v-if="tab == 'tab-2'">
        <div
          class="d-flex justify-center align-center"
          v-text="parentCategory[0]"
          style="font-size: 1.7em; border-bottom: 1px solid black; padding: 5px"
          @click="toCategory(parentCategory[1], true)"
        ></div>
        <ul
          v-for="(list, idx) in data_list"
          :key="idx"
          style="
            border-bottom: 1px solid black;
            font-size: 20px;
            padding: 5px 5px 5px 30px;
            list-style-type: square;
          "
        >
          <div>
            <li>
              <button @click="toCategory(list, true)">
                {{ list.category_name }}
              </button>
            </li>
            <div
              v-if="list.children.length > 0"
              style="margin-left: -30px; border-bottom: 1px solid black"
            ></div>

            <ul
              v-for="(child, idx) in list.children"
              :key="idx"
              style="
                font-size: 20px;
                padding: 5px 5px 5px 30px;
                list-style-type: square;
              "
            >
              <div>
                <li>
                  <button @click="toCategory(child, true)">
                    {{ child.category_name }}
                  </button>
                </li>
              </div>
            </ul>
          </div>
        </ul>
      </div>
      <div v-else>
        <div
          class="d-flex justify-center align-center"
          v-text="parentCategory[0]"
          style="font-size: 1.7em; border-bottom: 1px solid black; padding: 5px"
          @click="toCategory(parentCategory[1], false)"
        ></div>
        <ul
          v-for="(list, idx) in data_list"
          :key="idx"
          style="
            border-bottom: 1px solid black;
            font-size: 20px;
            padding: 5px 5px 5px 30px;
            list-style-type: square;
          "
        >
          <li>
            <button @click="toCategory(list, false)">
              {{ list.category_name }}
            </button>
          </li>
          <div
            v-if="list.children.length > 0"
            style="margin-left: -30px; border-bottom: 1px solid black"
          ></div>
          <ul
            v-for="(child, idx) in list.children"
            :key="idx"
            style="
              font-size: 20px;
              padding: 5px 5px 5px 30px;
              list-style-type: square;
            "
          >
            <li>
              <button @click="toCategory(child, false)">
                {{ child.category_name }}
              </button>
            </li>
          </ul>
        </ul>
      </div>
      <v-list-item style="margin-top: 100vh"></v-list-item>
    </div>
  </div>
</template>
<script>
export default {
  name: "ListCategory",
  data() {
    return {
      noData: false,
      stDrawer: true,
    };
  },
  props: {
    data_list: Array,
    storeId: Number,
    parentCategory: Array,
    tab: String,
  },
  methods: {
    isObject: function (val) {
      return typeof val === "object";
    },
    toCategory(data, cstore) {
      if (cstore) {
        if (this.isObject(data)) {
          if (
            this.$router.history.current.path ==
            `/store/${this.storeId}/${data.slug}`
          ) {
            this.stDrawer = false;
          } else {
            this.$router.push(`/store/${this.storeId}/${data.slug}`);
          }
        } else {
          if (
            this.$router.history.current.path ==
            `/store/${this.storeId}/${data}`
          ) {
            this.stDrawer = false;
          } else {
            this.$router.push(`/store/${this.storeId}/${data}`);
          }
        }
      } else {
        if (this.isObject(data)) {
          if (data.is_child > 0) {
            if (
              this.$router.history.current.path ==
              `/items/category/${data.slug}`
            ) {
              this.stDrawer = false;
            } else {
              this.$router.push(`/items/category/${data.slug}`);
            }
          } else {
            if (
              this.$router.history.current.path ==
              `/items/category/c/${data.slug}/1`
            ) {
              this.stDrawer = false;
            } else {
              this.$router.push(`/items/category/c/${data.slug}/1`);
            }
          }
        } else {
          if (this.$router.history.current.path == `/items/category/${data}`) {
            this.stDrawer = false;
          } else {
            this.$router.push(`/items/category/${data}`);
          }
        }
      }

      this.$emit("statusDrawer", this.stDrawer);
    },
  },
};
</script>
