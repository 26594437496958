<template>
  <div>
    <div class="d-flex align-center">
      <Warning :openDialog="dialog" @handle-dialog="handleDialog" />
    </div>

    <div
      v-if="isLogin > 0"
      :class="isProductDetail ? 'row-footer-detail' : 'row-footer'"
    >
      <v-card elevation="3" tile>
        <v-row>
          <v-col cols="12" v-if="isProductDetail" class="me-8 text-center">
            <div>
              <v-btn
                color="#ff0090"
                class="white--text text-center"
                :disabled="
                  itemDetail.stock_total == 0 ||
                  itemDetail.is_stock == 0 ||
                  itemDetail.is_bulk_item == 1 ||
                  itemDetail.item_status == 0
                "
                style="width: 84%"
                small
                @click.prevent="addCart(itemDetail)"
              >
                <v-icon left dark v-if="itemDetail.stock_total !== 0">
                  mdi-cart-outline
                </v-icon>
                <!-- {{ $t("message.btn-add-cart") }} -->
                <div v-if="itemDetail.stock_total !== 0" style="color: white">
                  {{ $t("message.btn-add-cart") }}
                </div>
                <div
                  v-else-if="itemDetail.stock_total == 0"
                  style="color: white"
                >
                  売り切れ
                </div>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="2" class="" style="">
            <div class="ps-1">
              <router-link to="/carts" style="text-decoration: none">
                <div
                  class=""
                  style="
                    background-color: black;
                    width: fit-content;
                    border-radius: 50%;
                  "
                >
                  <v-icon
                    class="p-5"
                    style="padding: 7px"
                    size="21"
                    color="#ff0090"
                  >
                    mdi-cart
                  </v-icon>
                </div>
              </router-link>
            </div>
          </v-col>
          <v-col cols="2" style="padding-left: 0px; padding-right: 0px">
            <div>
              <p class="black--text" small>お会計</p>
              <p class="black--text">Cashier</p>
            </div>
          </v-col>
          <v-col cols="4" style="padding-left: 0px; padding-right: 0px">
            <div>
              <p class="black--text" style="font-size: 10px">
                {{ $t("message.total-bill") }}: ¥{{
                  formatDecWithoutComa(totalOrderPrice)
                }}
              </p>
              <p class="black--text" style="font-size: 10px">
                {{ $t("message.total-spending") }}: {{ totalCart }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="2"
            class="pe-3"
            style="padding-left: 0px; padding-right: 10px"
          >
            <v-btn
              depressed
              small
              color="black"
              class="white--text text-lowercase"
              style="padding-left: 10px; padding-right: 5px"
              @click="$router.push('/carts')"
            >
              {{ $t("message.continue-to-order") }}
              <v-icon right dark class="hide_icon"> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import moneyFormat from "../helpers/moneyFormat";
import Swal from "sweetalert2";
import Warning from "./Warning.vue";
import formatDecimal from "../helpers/formatDecimal";
import formatDecWithoutComa from "../helpers/formatDecWithoutComa";

export default {
  data() {
    return {
      isProductDetail: false,
      message: {},
      dialog: false,
      id: "",
      storeId: "",
    };
  },
  components: {
    Warning,
  },
  created() {
    this.getRouteName();
    this.getALlShopData();
  },
  computed: {
    itemDetail() {
      return this.$store.state.itemDetail_module.itemDetailResult;
    },
    totalCart() {
      return this.$store.state.carts_module.badgeLength;
    },
    totalOrderPrice() {
      return this.$store.state.carts_module.totalItemPrice;
    },
    isLogin() {
      return $cookies.get("isLogin");
    },
  },
  methods: {
    handleDialog(val) {
      if (val.selected == "yes") {
        this.$gtag.event("add-cart-click", {
          event_category: "Enhanced Ecommerce",
          event_label: this.itemDetail.item_name,
          value: this.itemDetail.price,
        });
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.id,
          storeId: this.storeId,
          message: {
            title: this.message.title,
          },
        });
      }
      this.dialog = false;
    },
    async getALlShopData() {
      if (this.isLogin > 0) {
        await this.$store.dispatch("carts_module/fetchCarts");
      }
    },
    addAnalytic() {
      // this.$gtag.event("add-cart-click", {
      //   event_category: "Enhanced Ecommerce",
      //   event_label: data.item_name,
      //   value: data.price,
      // });
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    formatDecimal(money) {
      return formatDecimal(money);
    },
    formatDecWithoutComa(money) {
      return formatDecWithoutComa(money);
    },
    getRouteName() {
      let page = this.$route.name;
      if (page === "ItemDetail") this.isProductDetail = true;
      else this.isProductDetail = false;
    },
    addCart(data) {
      let cookies = $cookies.get("isLogin");
      this.id = data.id;
      this.storeId = data.store_id;
      if (cookies == 0 && localStorage.usr_tkn) {
        Swal.fire({
          icon: "error",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        if (data.is_alcohol_item == 1) {
          this.dialog = true;
        } else {
          this.dialog = false;
          this.$gtag.event("add-cart-click", {
            event_category: "Enhanced Ecommerce",
            // event_label: data.item_name,
            // value: data.price,
            event_label: this.itemDetail.item_name,
            value: this.itemDetail.price,
          });
          this.message.title = this.$t("message.title-add-carts");
          this.$store.dispatch("carts_module/addCarts", {
            itemId: data.id,
            storeId: data.store_id,
            message: {
              title: this.message.title,
            },
          });
        }
      }
    },
  },
};
</script>

<style>
@media only screen and (max-width: 330px) {
  .hide_icon {
    display: none !important;
  }
}
.row-footer {
  margin-top: 30px;
  position: fixed;
  height: 60px !important;
  bottom: 3.6em !important;
  width: 100%;
  color: white;
  font-size: 12px;
  font-weight: 400;
  /* background-color: #3f51b5; */
}
.row-footer-detail {
  margin-top: 30px;
  position: fixed;
  height: 60px;
  bottom: 4.5em;
  width: 100%;
  color: white;
  font-size: 12px;
  font-weight: 400;
  /* background-color: #3f51b5; */
}
</style>
