<template>
  <div class="row-category">
    <div v-if="data_store.length > 0">
      <Section
        :title_name="title_slide"
        :link="link_slide"
        :has_show="has_show_section"
        :sub_name="sub_name_section"
      />
    </div>
    <v-container>
      <div>
        <div v-if="data_store.length > 0">
          <v-row style="overflow-x: auto; flex-wrap: nowrap" class="row-scroll">
            <v-col
              v-for="store in data_store"
              cols="4"
              md="3"
              lg="3"
              sm="3"
              :key="store.id"
              class="parent-style-product pe-0"
            >
              <StoreList :store="store" :loading="is_loading" />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
    <!-- <div v-else-if="data_store.length == 0">
      <p class="font-weight-bold mt-4 grey--text">
        {{ $t("message.no-store-in-category") }}
      </p>
    </div> -->
  </div>
</template>

<script>
import { mdiArrowLeft, mdiChevronRight } from "@mdi/js";
import Skeleton from "@/components/skeleton/Skeleton";
import Section from "../components/Section.vue";

import StoreList from "./StoreList.vue";
export default {
  name: "StoreSlide",
  components: {
    StoreList,
    Section,
    Skeleton,
  },
  props: {
    data_store: Array,
    title_slide: String,
    link_slide: String,
    is_loading: Boolean,
    sub_name_section: String,
    has_show_section: Boolean,
  },
  data() {
    return {
      iconViewAll: mdiChevronRight,
    };
  },
};
</script>

<style>
.text_title {
  /* text-transform: capitalize; */
  border-left: 10px solid #ff0090;
  font-size: 16px;
  color: #424242;
}
.row-scroll::-webkit-scrollbar {
  display: none;
}
</style>
