<template>
  <!-- <v-container> -->
  <div class="row-footer-sidebar">
    <v-navigation-drawer
      v-model="favoriteMenu"
      :mini-variant="miniVariant"
      :clipped="clipped"
      color="#ff0090"
      right
      dark
      hide-overlay
      height="100vh"
      width="100%"
      app
      class="nav-drawer drawer-margin-top"
      temporary
      stateless
    >
      <template>
        <div v-if="isLogin == 0" class="sidebar-content-before-login"></div>
        <div v-else-if="isLogin == 1">
          <v-tabs v-model="tab" grow light active-class="isActive">
            <v-tab href="#tab-1" style="width: 50%">お気に入り店舗</v-tab>
            <v-tab href="#tab-2" style="width: 50%" @click="itemFavorite"
              >お気に入り登録商品</v-tab
            >
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-1'">
              <v-card flat class="fav-menu-height">
                <div>
                  <v-container>
                    <v-row style="padding: 0 5px 0 0">
                      <v-col
                        v-for="(list, idx) in favStore"
                        cols="4"
                        md="3"
                        lg="3"
                        sm="3"
                        :key="idx"
                        class="parent-style-product pe-0"
                        style="padding-bottom: 1px"
                      >
                        <StoreList
                          @openDrawer="getStatusDrawer"
                          :store="list"
                          :loading="isLoadingFavStore"
                          @click="favoriteMenu = false"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-pagination
                    v-if="!isLength"
                    color="#ff0090"
                    v-model="pageFavStore"
                    :total-visible="5"
                    :length="favStoreHeader.last_page"
                    @input="nextpageFavStore"
                    style="margin-top: 10px"
                  ></v-pagination>
                </div>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat class="fav-menu-height">
                <div v-if="listProductFav.length > 0">
                  <v-container>
                    <v-row style="padding: 0 5px 0 0">
                      <v-col
                        v-for="(list, idx) in listProductFav"
                        cols="4"
                        md="3"
                        lg="3"
                        sm="3"
                        :key="idx"
                        class="parent-style-product pe-0"
                        style="padding-bottom: 1px"
                      >
                        <Product
                          :data="list"
                          @data-slug="getModal"
                          :loading="isLoadingFav"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-pagination
                    v-if="!isLengthItemFavorite"
                    color="#ff0090"
                    v-model="pageFav"
                    :total-visible="5"
                    :length="headerItemFavs.last_page"
                    @input="nextPageFav"
                  ></v-pagination>
                  <Modal
                    :item_slug="slug"
                    :showDialog="dialog"
                    @get-modal="handleModal"
                    @dialogVisible="dialog"
                  />

                  <ConfirmationProduct
                    :data_product="data_dialog_confirm"
                    :showDialog="dialog_confirmation_product"
                    @close="getCloseModal"
                    @dialogVisible="dialog_confirmation_product"
                  />

                  <Warning
                    :openDialog="dialog_warning"
                    @handle-dialog="handleDialog_warning"
                  />
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- NAV CATEGORY -->
    <v-navigation-drawer
      v-model="categoriesMenu"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      color="#ff0090"
      right
      dark
      hide-overlay
      height="100vh"
      width="100%"
      app
      class="nav-drawer drawer-margin-top"
      temporary
      stateless
    >
      <template>
        <div>
          <v-tabs
            grow
            light
            v-model="tab"
            active-class="isActive"
            style="overflow-y: hidden"
          >
            <v-tab href="#tab-1" @click="defaultSelect" style="width: 50%"
              >TAJIMINNGO</v-tab
            >
            <v-tab href="#tab-2" @click="defaultSelect" style="width: 50%">
              <div class="d-flex flex-column justify-content-center flex-wrap">
                <div style="font-size: 0.7em">店舗内カテゴリー検索</div>
                <div style="font-size: 1.2em">
                  {{ limitString(this.getStore.store_name, 10, true) }}
                </div>
              </div>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-1'">
              <v-card flat style="height: 100vh; background-color: #ff0090">
                <div v-if="!isLoading">
                  <v-row
                    style="
                      overflow-x: auto;
                      flex-wrap: nowrap;
                      overflow-y: hidden;
                    "
                    class="row-scroll"
                  >
                    <v-col
                      v-for="(list, idx) in listCategories"
                      cols="4"
                      md="3"
                      lg="2"
                      sm="3"
                      :key="idx"
                      class="parent-style-product"
                      style="padding-right: 0px"
                    >
                      <div id="category-container">
                        <v-card
                          class="p-0 category-card"
                          elevation="0"
                          style="border-radius: 0"
                          @click="getCategory(list, false, list.id)"
                        >
                          <v-img
                            height="60"
                            alt=""
                            :lazy-src="list.image_url"
                            :src="list.image_url"
                            contain
                            style="margin-top: 10px"
                          ></v-img>
                          <v-card-text
                            style="
                              height: 30px;
                              padding-top: 5px;
                              border-radius: 0;
                              margin-bottom: 13px;
                              background-color: black;
                            "
                            v-bind:class="{ isActive: list.id === activeId }"
                          >
                            <div
                              class="item-text-header"
                              style="
                                color: white;
                                text-align: center;
                                white-space: nowrap;
                              "
                            >
                              {{ limitString(list.category_name, 5, true) }}
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                  <div
                    style="
                      width: 100%;
                      padding: 0 5px 5px 5px;
                      overflow-y: scroll;
                      height: 1000px;
                    "
                  >
                    <ListCategory
                      :data_list="category"
                      :parentCategory="categoryAllName"
                      :tab="tab"
                      @statusDrawer="getStatus"
                    />
                  </div>
                </div>
                <div v-else class="skeleton-category">
                  <SkeletonMenuCategory />
                </div>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat style="height: 100vh; background-color: #ff0090">
                <div v-if="!isLoadingCategoryByStore">
                  <div v-if="listCategoryByStore.length > 0">
                    <v-row
                      style="
                        overflow-x: auto;
                        flex-wrap: nowrap;
                        overflow-y: hidden;
                      "
                      class="row-scroll"
                    >
                      <v-col
                        v-for="(list, idx) in listCategoryByStore"
                        cols="4"
                        md="3"
                        lg="2"
                        sm="3"
                        :key="idx"
                        class="parent-style-product"
                        style="padding-right: 0px"
                      >
                        <div>
                          <v-card
                            class="p-0"
                            elevation="0"
                            style="border-radius: 0"
                            @click="getCategory(list, true, list.id)"
                          >
                            <v-img
                              height="60"
                              alt=""
                              :lazy-src="list.image_url"
                              :src="list.image_url"
                              contain
                              style="margin-top: 10px"
                            ></v-img>

                            <v-card-text
                              style="
                                height: 30px;
                                padding-top: 5px;
                                background-color: black;
                                border-radius: 0;
                                margin-bottom: 13px;
                              "
                              v-bind:class="{ isActive: list.id === activeId }"
                            >
                              <div
                                class="item-text-header"
                                style="
                                  color: white;
                                  text-align: center;
                                  white-space: nowrap;
                                "
                              >
                                {{ limitString(list.category_name, 5, true) }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-col>
                    </v-row>
                    <div
                      style="
                        width: 100%;
                        padding: 0 5px 5px 5px;
                        overflow-y: scroll;
                        height: 1000px;
                      "
                    >
                      <ListCategory
                        :data_list="categoryStore"
                        :storeId="storeId"
                        :parentCategory="categoryStoreName"
                        :tab="tab"
                        @statusDrawer="getStatus"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div
                      style="
                        background-color: white;
                        padding: 10px;
                        text-align: center;
                        font-size: 1.7em;
                        border-top: 3px solid #ff0090;
                        margin-left: 3px;
                        margin-right: 3px;
                      "
                    >
                      {{ $t("message.category-not-found") }}
                    </div>
                  </div>
                </div>
                <div v-else class="skeleton-category">
                  <SkeletonMenuCategory />
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- NAV SEARCH -->
    <v-navigation-drawer
      v-model="search_menu"
      :mini-variant="miniVariant"
      :clipped="clipped"
      color="#ff0090"
      bottom
      height="20vh"
      style="top: 75%"
      hide-overlay
      width="100%"
      app
      class="nav-drawer_search"
      temporary
      fixed
    >
      <template>
        <v-tabs v-model="tab" grow active-class="active-search">
          <v-tab href="#tab-1">TAJIMINNGO全体から検索</v-tab>
          <v-tab href="#tab-2">{{ this.getStore.store_name }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="background-color: #ff0090">
          <v-tab-item :value="'tab-1'">
            <div style="margin: 20px 10px">
              <v-row class="">
                <div class="col-12">
                  <v-form @submit.prevent="submitKeyword()">
                    <v-text-field
                      v-model="keyword"
                      class=""
                      style="height: 3rem"
                      hide-details="false"
                      solo
                      append-icon="mdi-magnify"
                      @click:append="submitKeyword"
                    ></v-text-field>
                  </v-form>
                </div>
              </v-row>
            </div>
          </v-tab-item>

          <v-tab-item :value="'tab-2'">
            <div style="margin: 20px 10px">
              <v-row class="">
                <div class="col-12">
                  <v-form @submit.prevent="submitKeywordItemByStore()">
                    <v-text-field
                      v-model="keywordItemByStore"
                      class=""
                      style="height: 3rem"
                      hide-details="false"
                      solo
                      append-icon="mdi-magnify"
                      @click:append="submitKeywordItemByStore"
                    ></v-text-field>
                  </v-form>
                </div>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-navigation-drawer>

    <!-- OPEN MENU SIDEBAR -->
    <v-navigation-drawer
      v-model="sideMenu"
      color="#ff0090"
      right
      dark
      hide-overlay
      height="100vh"
      app
      class="nav-drawer"
      fixed
      temporary
    >
      <template>
        <div
          v-if="isLogin == 0"
          class="d-flex flex-column justify-center"
          style="height: 100vh"
        >
          <v-toolbar-title style="margin: 20px 0">
            <router-link to="/login" style="text-decoration: none">
              <span
                class="ml-6"
                style="color: white; margin-top: 20px; cursor: pointer"
                >{{ $t("message.label-login") }}</span
              >
            </router-link>
          </v-toolbar-title>
          <v-toolbar-title style="margin: 20px 0">
            <router-link to="/tempRegister" style="text-decoration: none">
              <span
                class="ml-6"
                style="color: white; margin-top: 20px; cursor: pointer"
                >{{ $t("message.label-register") }}</span
              >
            </router-link>
          </v-toolbar-title>
        </div>
        <div
          v-else-if="isLogin == 1"
          class="d-flex flex-column justify-center"
          style="height: 90vh"
        >
          <v-toolbar-title style="margin: 20px 0">
            <router-link to="/account" style="text-decoration: none">
              <span
                class="ml-6"
                style="color: white; margin-top: 20px; cursor: pointer"
                >{{ $t("message.title-account") }}</span
              >
            </router-link>
          </v-toolbar-title>
          <v-toolbar-title style="margin: 20px 0">
            <router-link to="/address" style="text-decoration: none">
              <span
                class="ml-6"
                style="color: white; margin-top: 20px; cursor: pointer"
                >{{ $t("message.title-address-management") }}</span
              >
            </router-link>
          </v-toolbar-title>

          <v-toolbar-title style="margin: 20px 0">
            <router-link to="/order-history" style="text-decoration: none">
              <span
                class="ml-6"
                style="color: white; margin-top: 20px; cursor: pointer"
                >{{ $t("message.order-history-product") }}</span
              >
            </router-link>
          </v-toolbar-title>
          <v-toolbar-title style="margin: 20px 0">
            <router-link to="/carts" style="text-decoration: none">
              <span
                class="ml-6"
                style="color: white; margin-top: 20px; cursor: pointer"
                >{{ $t("message.label-in-carts") }}</span
              >
            </router-link>
          </v-toolbar-title>
          <v-toolbar-title style="margin: 20px 0" @click.prevent="handleLogout">
            <p class="ml-3 ps-3" style="color: white; cursor: pointer">
              {{ $t("message.btn-logout") }}
            </p>
          </v-toolbar-title>
        </div>
      </template>
    </v-navigation-drawer>

    <v-bottom-navigation
      :value="value"
      color="white"
      background-color="#ff0090"
      style="z-index: 99999 !important"
    >
      <v-btn
        @click="
          {
            home,
              (sideMenu = false),
              (favoriteMenu = false),
              (categoriesMenu = false);
            search_menu = false;
          }
        "
      >
        <span style="color: #f9f9f9; font-size: 0.8em">TOP</span>
        <v-icon style="color: #f9f9f9">mdi-home-outline</v-icon>
      </v-btn>

      <v-btn @click="toFavoriteMenu">
        <span style="color: #f9f9f9; font-size: 0.8em">お気に入り</span>
        <v-icon style="color: #f9f9f9">mdi-heart</v-icon>
      </v-btn>

      <v-btn @click="toCategoryMenu">
        <span style="color: #f9f9f9; font-size: 0.8em">カテゴリー 検索</span>
        <v-icon style="color: #f9f9f9">mdi-folder-outline</v-icon>
      </v-btn>

      <v-btn @click="showSearchMenu">
        <span style="color: #f9f9f9">検索</span>
        <v-icon style="color: #f9f9f9"> mdi-magnify </v-icon>
      </v-btn>

      <v-btn
        @click.stop="
          {
            (sideMenu = !sideMenu),
              (favoriteMenu = false),
              (categoriesMenu = false),
              (searchMenu = false);
          }
        "
      >
        <span style="color: #f9f9f9; font-size: 0.8em">メニュー</span>
        <v-icon style="color: #f9f9f9">mdi-menu</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
  <!-- </v-container> -->
</template>

<script>
import ProductSlide from "../ProductSlide.vue";
import StoreSlide from "../StoreSlide.vue";
import StoreList from "../StoreList.vue";
import Product from "../Product.vue";
import Modal from "../DetailModal.vue";
import ConfirmationProduct from "../ConfirmationProduct.vue";
import Warning from "../Warning.vue";
import ListCategory from "../ListCategory.vue";
import { mdiMagnify, mdiArrowLeft, mdiCloseThick } from "@mdi/js";
import SkeletonMenuCategory from "../skeleton/SkeletonMenuCategory.vue";
export default {
  name: "Sidebar",
  components: {
    ProductSlide,
    StoreSlide,
    StoreList,
    Product,
    ListCategory,
    Modal,
    SkeletonMenuCategory,
    ConfirmationProduct,
    Warning,
  },
  data() {
    return {
      keyword: "",
      keywordItemByStore: "",
      value: 1,
      isProductDetail: false,
      isLoadingFavStore: true,
      isLoadingCategoryByStore: false,
      isLoadingFav: false,
      logo: require("../../assets/logo_kbase-removebg.png"),
      clipped: false,
      sideMenu: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      favoriteMenu: false,
      categoriesMenu: false,
      search_menu: false,
      tab: null,
      isLoading: true,
      category: [],
      categoryStore: [],
      keyword: "",
      closeThick: mdiCloseThick,
      iconSearch: mdiMagnify,
      iconArrowLeft: mdiArrowLeft,
      categoryAllName: [],
      categoryStoreName: [],
      activeId: 0,
      pageFavStore: 1,
      isFetch: false,
      slug: "",
      dialog: false,
      dialog_confirmation_product: false,
      data_dialog_confirm: {},
      data_dialog_warning: null,
      dialog_warning: false,
      pageFav: 1,
      getStore: this.$store.state.shop_module.storeByCondition,
      storeId: null,
    };
  },
  watch: {
    tab(val) {
      if (val == "tab-2") {
        let storage_store = localStorage.getItem("getStore");
        if (storage_store) {
          let store = JSON.parse(storage_store);
          this.storeId = store.id;
        } else {
          this.storeId =
            this.$store.state.shop_module.storeByCondition.store_id;
        }
      }
    },
  },
  created() {
    this.getRouteName();
    //this.itemFavorite();
    // this.fetchShopSearch();
  },
  beforeMount() {
    this.setDefaultLogin();
    this.fetchShopSearch();
    this.handleSearchMenu();

    //this.fetchItemFavorite();
    // this.getSearchItemByStore();
  },
  mounted() {
    this.getSearchItem();
    // this.getSearchItemByStore()
  },
  methods: {
    getStatus(val) {
      this.categoriesMenu = val;
    },
    handleDialog_warning(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.data_dialog_warning.id,
          storeId: this.data_dialog_warning.store_id,
          message: {
            title: this.message.title,
          },
        });
        setTimeout(() => {
          this.dialog = false;
        }, 1800);
      }
      this.dialog_warning = false;
    },
    getCloseModal() {
      this.dialog = false;
      this.dialog_confirmation_product = false;
    },
    handleModal(data) {
      if (data[0] == "ConfirmProduct") {
        this.data_dialog_confirm = data[1];
        this.dialog_confirmation_product = true;
      } else if (data[0] == "dialog_alcohol") {
        this.data_dialog_warning = data[1];
        this.dialog_warning = true;
      } else {
        this.dialog = false;
      }
    },
    getModal(data) {
      if (typeof data === "string") {
        this.slug = data;
        this.dialog = true;
      } else {
        this.data_dialog_confirm = data;
        this.dialog_confirmation_product = true;
      }
    },
    getStatusDrawer(status) {
      this.favoriteMenu = status;
    },
    handleSearchMenu() {
      let cekRoute = this.$route.name;
      if (cekRoute === "SearchResult") {
        this.search_menu = true;
        let storage_store = localStorage.getItem("getStore");
        if (storage_store) {
          this.getStore = JSON.parse(storage_store);
        } else {
          this.getStore = this.$store.state.shop_module.storeByCondition;
        }
      }
    },
    showSearchMenu() {
      this.search_menu = !this.search_menu;
      this.favoriteMenu = false;
      this.categoriesMenu = false;
      this.sideMenu = false;
      this.fetchShopSearch();
    },
    async fetchShopSearch() {
      if (this.search_menu) {
        // CEK LOCALSTORAGE
        let storage_store = localStorage.getItem("getStore");
        let data_store = JSON.parse(storage_store);

        if (data_store !== null) {
          this.getStore = JSON.parse(storage_store);
        } else {
          // TRIGGER FIRST
          if (this.$route.name !== "ShopDetail") {
            await this.$store.dispatch("shop_module/fetchStoreByCondition");
            this.getStore = this.$store.state.shop_module.storeByCondition;
          }
        }
      }
    },
    // SUBMIT SEARCH ITEM BY STORE
    submitKeywordItemByStore() {
      let storage_store = localStorage.getItem("getStore");
      let cekStoreLocalstorage = JSON.parse(storage_store);
      if (cekStoreLocalstorage) {
        this.$router.push(
          `/items?search=${this.keywordItemByStore}&storeId=${cekStoreLocalstorage.id}`
        );
      } else {
        let store = this.$store.state.shop_module.storeByCondition;
        if (store.store_id === undefined) {
          this.$router.push(
            `/items?search=${this.keywordItemByStore}&storeId=${store.id}`
          ).catch(err => {});
        } else {
          this.$router.push(
            `/items?search=${this.keywordItemByStore}&storeId=${store.store_id}`
          ).catch(err => {});
        }
      }
    },

    // SUBMIT SEARCH ITEM
    submitKeyword() {
      this.$router.push(`/items?search=${this.keyword}`);
    },
    async getSearchItem() {
      let routeName = this.$route.name;
      if (routeName === "SearchResult") {
        let keyparams = this.$route.query.search;
        let store = this.$route.query.storeId;
        if (store == undefined) {
          await this.$store.dispatch("itemList_module/itemSearch", {
            keyword: keyparams,
            storeId: "",
          });
        } else {
          await this.$store.dispatch("itemList_module/itemSearch", {
            keyword: keyparams,
            storeId: store,
          });
        }
      }
    },
    setDefaultLogin() {
      let islogin = $cookies.get("isLogin");
      if (islogin === 0 || islogin === null) {
        $cookies.set("isLogin", 0);
      }
    },
    getCategory(data, cstore, id) {
      this.activeId = id;

      if (cstore) {
        this.categoryStore = data.children;
        this.categoryStoreName = [data.category_name, data.slug];
      } else {
        this.category = data.children;
        this.categoryAllName = [data.category_name, data.slug];
      }
    },
    toCategoryParent(data, storeId) {
      if (storeId) {
        this.$router.push(`/store/${this.storeId}/${data.slug}`);
      } else {
        if (data.is_child > 0) {
          this.$router.push(`/items/category/${data.slug}`);
        } else {
          this.$router.push(`/items/category/c/${data.slug}/1`);
        }
      }
    },
    getRouteName() {
      let page = this.$route.name;
      if (page === "ItemDetail") this.isProductDetail = true;
      else this.isProductDetail = false;
    },
    checkIsHome() {
      let home = this.$route.name;
      if (home === "Home") {
        return 0;
      } else {
        this.$router.push("/");
      }
    },
    async storeByCondition() {
      let dataStore = localStorage.getItem("getStore");
      let store = JSON.parse(dataStore);
      if (store !== null) {
        this.getStore = store;
        if (this.getStore) {
          this.getCategoryByStore(this.getStore.id);
        }
      } else {
        // IF ROUTE SHOP DETAIL
        if (this.$route.name !== "ShopDetail") {
          let data = await this.$store.dispatch(
            "shop_module/fetchStoreByCondition"
          );
          let res = this.$store.state.shop_module.storeByCondition;
          this.getStore = res;

          if (this.getStore) {
            this.getCategoryByStore(this.getStore.store_id);
          }

          return data;
        } else {
          // let res = this.$store.state.shop_module.storeByCondition;

          if (this.getStore) {
            this.getCategoryByStore(this.getStore.id);
          }
        }
      }
    },
    async handleLogout() {
      await this.$store.dispatch("auth_module/logout", {
        message: {
          title: this.$t("message.title-logout-success-message"),
        },
      });
    },
    async getFavStore() {
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        this.isLoadingFavStore = true;
        let res = await this.$store.dispatch("shop_module/fetchStoreFav", {
          page: this.pageFavStore,
        });
        this.isLoadingFavStore = false;
        return res;
      }
    },
    nextpageFavStore() {
      this.$store.dispatch("shop_module/fetchStoreFav", {
        page: this.pageFavStore,
      });
    },
    async fetchItemFavorite() {
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        this.isLoadingFav = true;
        let data = await this.$store.dispatch(
          "itemFavorite_module/itemFavorite",
          {
            page: this.pageFav,
          }
        );
        this.isLoadingFav = false;
        this.isFetch = true;
        return data;
      }
    },
    nextPageFav() {
      this.$store.dispatch("itemFavorite_module/itemFavorite", {
        page: this.pageFav,
      });
    },
    itemFavorite() {
      if (this.isFetch == false) {
        this.fetchItemFavorite();
      }
    },
    async getAllCategories() {
      this.isLoading = true;
      let data = await this.$store.dispatch("category_module/getCategories");
      this.isLoading = false;

      let res = this.$store.state.category_module.allCategoryResult;
      this.category = res[0].children;
      this.categoryAllName = [res[0].category_name, res[0].slug];
      this.activeId = res[0].id;

      return data;
    },
    async getCategoryByStore(id) {
      this.isLoadingCategoryByStore = true;
      let data = await this.$store.dispatch(
        "category_module/getCategoryByStore",
        {
          id: id,
        }
      );

      let res = this.$store.state.category_module.categoryByStore;
      if (res.length > 0) {
        this.categoryStore = res[0].children;
        this.categoryStoreName = [res[0].category_name, res[0].slug];
        this.activeId = res[0].id;
      }

      this.isLoadingCategoryByStore = false;

      return data;
    },
    limitString: function (text, count, insertDots) {
      if (text) {
        return (
          text.slice(0, count) +
          (text.length > count && insertDots ? "..." : "")
        );
      }
    },
    toFavoriteMenu() {
      if (this.isLogin == 1) {
        if (this.favoriteMenu == false) {
          this.getFavStore();
          this.isFetch = false;
          this.tab = "tab-1";
        }
        this.favoriteMenu = !this.favoriteMenu;
        this.sideMenu = false;
        this.categoriesMenu = false;
      } else {
        this.toLogin;
      }
    },
    toCategoryMenu() {
      if (this.categoriesMenu == false) {
        this.storeByCondition();
        this.getAllCategories();
        //this.this.isFetch = false;
        this.tab = "tab-1";
      }

      this.categoriesMenu = !this.categoriesMenu;
      this.sideMenu = false;
      this.favoriteMenu = false;
    },
    defaultSelect() {
      let resAllCtg = this.$store.state.category_module.allCategoryResult;
      this.activeId = resAllCtg[0].id;
      this.category = resAllCtg[0].children;
      this.categoryAllName = [resAllCtg[0].category_name, resAllCtg[0].slug];

      let resStoreCtg = this.$store.state.category_module.categoryByStore;
      if (resStoreCtg.length > 0) {
        this.activeId = resStoreCtg[0].id;
        this.categoryStore = resStoreCtg[0].children;
        this.categoryStoreName = [
          resStoreCtg[0].category_name,
          resStoreCtg[0].slug,
        ];
      }
    },
  },
  computed: {
    home() {
      this.$router.push("/").catch(() => {});
    },
    toLogin() {
      this.$router.push("/login");
    },
    isLogin() {
      return $cookies.get("isLogin");
    },
    listProductFav() {
      return this.$store.state.itemFavorite_module.itemFavoriteResult;
    },
    headerItemFavs() {
      return this.$store.state.itemFavorite_module.headerItemFavs;
    },
    favStore() {
      return this.$store.state.shop_module.storeFav;
    },
    listCategories() {
      return this.$store.state.category_module.allCategoryResult;
    },
    listCategoryByStore() {
      return this.$store.state.category_module.categoryByStore;
    },
    favStoreHeader() {
      return this.$store.state.shop_module.storeFavHeader;
    },
    isLength() {
      return this.$store.state.shop_module.is_length;
    },
    isLengthItemFavorite() {
      return this.$store.state.itemFavorite_module.is_length;
    },
  },
};
</script>

<style>
.row-footer-sidebar {
  margin-top: 30px;
  position: fixed;
  /* height: 60px; */
  bottom: 0em;
  width: 100%;
  color: #ff0090;
  font-size: 12px;
  font-weight: 400;
}
.button-sidebar {
  display: flex;
  flex-direction: row-reverse;
}
.fav-menu-height {
  background-color: #ff0090 !important;
  border-radius: 0;
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 200px;
}
.nav-drawer {
  margin-top: 3rem;
}
.nav-drawer_search {
  /* margin-bottom: 3.5rem; */
  /* margin-bottom: 15rem; */
  /* top: 50%; */
  z-index: 0;
}
.active-search {
  background-color: #ff0090;
  color: white !important;
}
.grid-input-search {
  width: 95%;
  margin: 10px;
  /* height: 3rem; */
}

.sidebar-content {
  /* margin-top: 28rem; */
  margin-top: 20rem;
}
.sidebar-content-before-login {
  margin-top: 30rem;
}

/* .v-window__prev,
.v-window__next {
  z-index: 0 !important;
} */

/* .v-tab.v-tab {
  color: #ff0090 !important;
} */

.v-tab .v-tab--active {
  background-color: #ff0090 !important;
}

.v-application .primary--text {
  caret-color: #ff0090 !important;
  color: #ff0090 !important;
}

.isActive {
  background-color: #ff0090 !important;
  color: white !important;
}

@media only screen and (max-width: 414px) {
  .sidebar-content {
    margin-top: 28rem;
    /* margin-top: 20rem; */
  }
  .sidebar-content-before-login {
    margin-top: 38rem;
  }
  .nav-drawer_search {
    height: 20vh;
    margin-bottom: 3.5rem;
  }
}

.input-field-class {
  border: 2px solid #7a7a7a !important;
  background-color: white;
  color: black;
  width: 100%;
  outline: none;
  border-radius: 8px;
  padding: 10px 40px 10px 10px;
}

.v-navigation-drawer__content {
  overflow: hidden !important;
}

.skeleton-category {
  width: 100%;
  height: 100vh;
  background-color: white;
  padding-top: 5px;
}
</style>
