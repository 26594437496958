<template>
  <v-app-bar fixed color="black" dark :height="50">
    <v-spacer />
    <v-img
      :aspect-ratio="16 / 9"
      contain
      width="60"
      height="50"
      max-width="250"
      :src="logo"
      @click="checkIsHome"
    ></v-img>
    <v-spacer />
  </v-app-bar>
</template>

<script>
export default {
  name: "HeaderPage",
  data() {
    return {
      logo: require("../../assets/logo_kbase-removebg.png"),
    };
  },
  methods: {
    checkIsHome() {
      let home = this.$route.name;
      if (home === "Home") {
        return 0;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style></style>
