<template>
  <div>
    <!-- <div
      class="title_page"
      style="position: fixed; z-index: 1; width: 100%; background-color: white"
    > -->
    <div class="title_page">
      <Section :title_name="title" :has_show="false" />
      <!-- <v-spacer style="height: 10px" /> -->
    </div>

    <!-- <v-container class="content_page"> -->

    <div v-if="data_type == 'store'">
      <div v-if="data_list.length != 0">
        <v-container>
          <v-row style="padding: 0 5px 0 0">
            <v-col
              v-for="(list, idx) in data_list"
              cols="4"
              md="3"
              lg="3"
              sm="3"
              :key="idx"
              class="parent-style-product pe-0"
              style="padding-bottom: 1px"
            >
              <StoreList :store="list" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-else-if="data_type == 'news'">
      <div v-if="data_list.length != 0">
        <ShopNews :data_news="data_list" />
      </div>
    </div>
    <div v-else style="margin-bottom: 5rem">
      <div v-if="data_list.length != 0">
        <v-container>
          <v-row style="padding: 0 5px 0 0">
            <v-col
              v-for="(list, idx) in data_list"
              cols="4"
              md="3"
              lg="3"
              sm="3"
              :key="idx"
              class="parent-style-product pe-0"
              style="padding-bottom: 1px"
            >
              <Product :data="list" @data-slug="getModal" />
            </v-col>
          </v-row>
        </v-container>
        <Modal
          :item_slug="slug"
          :showDialog="dialog"
          @get-modal="handleModal"
          @dialogVisible="dialog"
        />

        <ConfirmationProduct 
          :data_product="data_dialog_confirm"
          :showDialog="dialog_confirmation_product"
          @close="getCloseModal"
          @dialogVisible="dialog_confirmation_product"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmationProduct from '../components/ConfirmationProduct.vue'
import StoreList from "../components/StoreList.vue";
import ShopNews from "../components/ShopNews.vue";
import Product from "../components/Product.vue";
import Section from "../components/Section.vue";
import Modal from "../components/DetailModal.vue";

export default {
  name: "SeeMorePage",
  data() {
    return {
      slug: "",
      dialog: false,
      dialog_confirmation_product: false,
      data_dialog_confirm: {},
    };
  },
  components: {
    StoreList,
    Section,
    ShopNews,
    Product,
    Modal,
    ConfirmationProduct
  },
  methods: {
    getCloseModal() {
      this.dialog = false
      this.dialog_confirmation_product = false
    },
    handleModal(data) {
      if(data[0] == 'ConfirmProduct') {
        this.data_dialog_confirm = data[1]
        this.dialog_confirmation_product = true
      } else {
        this.dialog = false
      }
    },
    getModal(data) {
      if(typeof data === 'string') {
        this.slug = data;
        this.dialog = true;
      } else {
        this.data_dialog_confirm = data
        this.dialog_confirmation_product = true
      }
    },
  },
  props: {
    title: String,
    data_list: Array,
    data_type: String,
  },
};
</script>
<style>
@media only screen and (min-width: 600px) and (max-width: 960px) {
  .title_page {
    margin-top: 5em;
    margin-bottom: 20px;
  }

  .content_page {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 599px) {
  .title_page {
    margin-top: 3em;
    margin-bottom: 20px;
  }

  .content_page {
    padding-top: 110px;
  }
}

@media only screen and (min-width: 961px) {
  .title_page {
    margin-top: 3em;
    margin-bottom: 20px;
  }

  .content_page {
    padding-top: 110px;
  }
}
</style>
