<template>
  <div>
    <div class="ps-2">
      <HeaderPage />
    </div>
    <SeeMorePage
      :title="'最近チェックした商品'"
      :data_list="listProductLastSeen"
      :data_type="'product'"
    />
    <div class="" style="margin-bottom: 8rem">
      <v-pagination
        v-if="!isLength"
        color="#ff0090"
        v-model="page"
        :total-visible="5"
        :length="headerItemLastSeen.last_page"
        @input="nextPage"
      ></v-pagination>
    </div>
    <Cashier />
    <Sidebar />
  </div>
</template>
<script>
import SeeMorePage from "../components/SeeMorePage.vue";
import HeaderPage from "../components/developmentv2/HeaderPage.vue";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import Cashier from "../components/Cashier.vue";

export default {
  name: "LastSeenProduct",
  components: {
    SeeMorePage,
    HeaderPage,
    Sidebar,
    Cashier,
  },
  data() {
    return {
      isLoadingHStore: true,
      page: 1,
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  methods: {
    async itemLastSeen() {
      if ($cookies.get("isLogin") == 1) {
        this.isLoadingLast = true;
        let data = await this.$store.dispatch(
          "itemLastSeen_module/itemLastSeen",
          {
            page: this.page,
          }
        );
        this.isLoadingLast = false;
        return data;
      }
    },
    nextPage() {
      this.$store.dispatch("itemLastSeen_module/itemLastSeen", {
        page: this.page,
      });
    },
  },
  computed: {
    isLength() {
      return this.$store.state.itemLastSeen_module.is_length;
    },
    headerItemLastSeen() {
      return this.$store.state.itemLastSeen_module.headerItemLastSeen;
    },
    listProductLastSeen() {
      return this.$store.state.itemLastSeen_module.itemLastSeenResult;
    },
  },
  created() {
    this.itemLastSeen();
  },
};
</script>
