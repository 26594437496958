<template>
  <div>
    <div v-if="loading">
      <v-col>
        <v-row
          style="padding-right: 0px"
          v-for="i in 4"
          :key="i"
          cols="4"
          md="2"
          lg="2"
          sm="2"
        >
          <SkeletonShopNews />
        </v-row>
      </v-col>
    </div>

    <v-container v-else style="padding: 5px">
      <v-row dense>
        <v-col
          v-for="(item, i) in data_news"
          :key="i"
          lg="6"
          md="12"
          sm="12"
          xs="12"
        >
          <div v-if="item.is_active === 1">
            <router-link
              :to="'store/' + item.store_id"
              style="text-decoration: none; color: black"
            >
              <v-card
                outlined
                style="border-color: black; background-color: #f4f0ec"
                class="rounded-0"
              >
                <div
                  class="d-flex flex-nowrap justify-space-start"
                  style="height: 120px"
                >
                  <div
                    style="
                      width: 150px;
                      min-width: 150px;
                      max-width: 150px;
                      height: 120px;
                      background-color: white;
                      overflow: hidden;
                      display: flex;
                      justify-content: center;
                    "
                  >
                    <v-img
                      :src="item.image_url"
                      :lazy-src="item.image_url"
                    ></v-img>
                  </div>

                  <div
                    style="padding: 10px; min-width: 100px; overflow: hidden"
                  >
                    <v-col style="height: 150px" class="col-auto">
                      <v-row
                        v-text="limitString(item.store_name, 30, true)"
                        class="mb-1"
                        style="font-weight: bold"
                      ></v-row>
                      <v-row
                        v-text="limitString(item.news_content, 50, true)"
                        class="align-content-start"
                        style="
                          text-align: justify;
                          font-size: 0.8rem;
                          overflow: hidden;
                        "
                      ></v-row>
                    </v-col>
                  </div>
                </div>
              </v-card>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import SkeletonShopNews from "../components/skeleton/SkeletonShopNews.vue";

export default {
  name: "ShopNews",
  components: {
    SkeletonShopNews,
  },
  props: {
    data_news: Array,
    loading: Boolean,
  },
  methods: {
    limitString: function (text, count, insertDots) {
      return (
        text.slice(0, count) +
        (text.length > count && insertDots ? "..." : "")
      );
    },
  },
};
</script>
