
export default function formatToDecimal(money) {
  if (money > 0) {
    return (money).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
  } else  {
    return (money == null ? "" : money.toString()).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
  }
}


