<template>
  <v-container class="mb-2">
    <v-row style="max-height: 120px; padding: 0; flex-wrap: nowrap">
      <v-col class="col-4" style="padding: 0; margin-right: 5px">
        <v-skeleton-loader
          v-bind="attrs"
          type="image"
          class="rounded-0"
        ></v-skeleton-loader>
      </v-col>
      <v-col class="col-4" style="padding: 0; margin-right: 5px">
        <v-skeleton-loader
          v-bind="attrs"
          type="image"
          class="rounded-0"
        ></v-skeleton-loader>
      </v-col>
      <v-col class="col-4" style="padding: 0">
        <v-skeleton-loader
          v-bind="attrs"
          type="image"
          class="rounded-0"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-for="i in 7" :key="i">
      <v-col class="col" style="padding: 0">
        <v-skeleton-loader
          v-bind="attrs2"
          type="list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      height: 100,
      boilerplate: false,
      elevation: 0,
    },
    attrs2: {
      boilerplate: false,
      elevation: 0,
    },
  }),
};
</script>

<style>
.v-skeleton-loader__paragraph {
  padding: 0 16px 16px 16px !important;
}
</style>
